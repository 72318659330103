<template>
  <div class="login" v-loading="loading">
    <el-card>
      <div class="flex-between-center">
        <h1>个人用户登录</h1>
        <p>
          <a @click="link('Regist')">还没有账号?点击注册</a>
        </p>
      </div>
      <el-form
        ref="ruleform"
        label-position="left"
        label-width="100px"
        :model="form"
        :rules="rules"
        hide-required-asterisk
      >
        <el-form-item label="手机号：" prop="mobile">
          <el-input v-model="form.mobile" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item label="密码：" prop="password">
          <el-input
            v-model="form.password"
            type="password"
            placeholder="请输入密码"
            @keyup.enter.native="login('ruleform')"
          ></el-input>
        </el-form-item>
        <el-form-item label>
          <el-button type="danger" class="login" @click="login('ruleform')"
            >登录</el-button
          >
        </el-form-item>
      </el-form>
      <p class="text-right">
        <a @click="link('Reset')">忘记密码？点击重置</a>
      </p>
    </el-card>
  </div>
</template>

<script>
let _this
// @ is an alias to /src
export default {
  name: 'Login',
  data() {
    var validateMobile = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号'))
      } else {
        if (/^1[3456789]\d{9}$/.test(value)) {
          callback()
        } else {
          callback(new Error('请输入正确的手机号'))
        }
      }
    }
    return {
      form: { mobile: '', password: '' },
      rules: {
        mobile: {
          required: true,
          validator: validateMobile,
          message: '请输入手机号',
          trigger: ['blur', 'change'],
        },

        password: {
          required: true,
          message: '请输入密码',
          trigger: 'blur',
        },
      },
      loading: false,
    }
  },
  created() {
    _this = this
    this.init()
  },
  methods: {
    init() {},
    link(name) {
      this.$router.push({ name: name })
      // _this.$router.push({path:"/regist"})
    },
    go_url(url) {
      var script = document.createElement('script')
      // script.type = "text/javascript";
      script.src =
        'http://sev.netban.cn/api/user/synwp/' +
        this.$store.state.wps_user.mobile
      document.getElementsByTagName('head')[0].appendChild(script)
      script.onload = function() {}
      console.log(url)
    },
    login(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          _this.$http
            .post(process.env.VUE_APP_URL + 'login', this.form)
            .then(data => {
              _this.loading = true
              if (data.data.code == 200) {
                _this.loading = false
                _this.$message.success('登录成功')
                _this.Storage.setStorage('token', data.data.token)
                _this.Storage.setStorage('agent', data.data.agent)
                _this.$store.commit('setUser', data.data.data)
                // _this.go_url()
                // _this.link('Home')
                _this.$router.push({ path: '/' })
              } else {
                _this.$message.error(data.data.msg)
                _this.loading = false
              }
            })
            .catch(err => {
              _this.$message.error('网络错误，请重试')
              console.log(err)
              _this.loading = false
            })
        } else {
          return false
        }
      })
    },
  },
}
</script>
<style lang="less" scoped>
.login {
  font-weight: normal;
  font-size: 16px;
  color: #333;
  a {
    color: #434346;
  }
  a:hover {
    text-decoration: none;
    color: orange;
    cursor: pointer;
  }
  .flex-between-center {
    color: #434346;
    margin-bottom: 50px;
    h1 {
      font-size: 24px;
    }
    p {
      font-size: 18px;
    }
  }
  .el-card {
    width: 620px;
    margin: 60px auto;
    /deep/.el-card__body {
      padding: 40px;
    }
    .el-form {
      /deep/.el-form-item__label {
        font-size: 18px;
      }
    }
    .login {
      background: #e10133;
      width: 300px;
      color: #fff;
      margin-top: 20px;
      margin-left: 20px;
    }
  }
}
</style>
